/* :root {
  --secondaryBackgroundClr: #f1f0f0;
  --primaryBackgroundClr:#e7e6e6;
  --primaryText:#000;
  --secondaryText:#182268;
  --white:#fff;
  --button:#c1272d;
} */

ul {
    list-style-type: none;
    padding: 0;
    margin-left: -1rem;
}
li {
    padding: 0;
}

.foot-cont{
    background-color: #000;
    color: var(--white);
    border-top: 15px solid var(--secondaryText);
}

.whole-container {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    column-gap: 4rem;
    padding: 2rem 4.7rem 2rem 4.7rem;
}

.foot-logo
{
    padding-right: 1rem;
    border-right: 2px solid #fff;
    height: 13.5rem;
    width: 13.5rem;
}

.foot-logo img {
     height: 10rem;
    width: 10rem;
}

.foot-content {
    display: flex;
    /* flex: 25% 25% 25%; */
    justify-content:flex-start;
    align-items: flex-start;
    flex-direction: column;
   /* flex: 1; */
    row-gap: 1rem;
}

.content-linkedin
{
    display: flex;
    justify-content: centers;
    align-items: center;
    column-gap: 10rem;
}

.box p{
    /* width: 30%; */
    display: flex;
    flex-direction: column;
    /* row-gap: 2rem; */
}


.box p{
    font-size: 16px;
    font-style: normal;
line-height: 30px; 
}
.foot-link ul {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 1.3rem;
}
.foot-nav-links {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
  text-decoration: none;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.copyright {
    font-size: 14px;
    text-align: center;
    padding:0.5rem 0 1rem 0;
    margin-top: 1rem;
}
.copyright p
{
    font-weight: 600;
    margin-left: -1.5rem;
}

.linkedin-link
{
    align-self: flex-end;
    justify-self: flex-end;
    height: 3rem;
    width: 3rem;
    margin-bottom: 1rem;
    margin-right: 1.5rem;
}
.linkedin-link img
{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

@media screen and (max-width: 1250px) {
   .whole-container
   {
    padding: 2rem 3rem 2rem 3rem;
   }
}
@media screen and (max-width: 1050px) {
    .foot-logo{
        border: none;
        padding: 0;
        height: 10rem;
        width: 10rem;
    }
    .whole-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    row-gap: 2rem;
}
.foot-content {
    display: flex;
    justify-content:space-between;
    align-items: center;
}
}
@media screen and (max-width: 950px) {
    .whole-container {
    row-gap: 1rem;
}
    .foot-logo
    {
        height: 8rem;
        width: 8rem;
    }
    .foot-logo img
    {
        height: 100%;
        width: 100%;
    }
    .foot-nav-links
    {
        font-size: 15px;
    }
   .box p {
    font-size: 15px;
   }
}
@media screen and (max-width: 950px) {
    .foot-nav-links
    {
        font-size: 14px;
    }
   .box p {
    font-size: 14px;
   }
   .copyright p
   {
    margin-left: 0;
    font-size: 15px;
   }
}
@media screen and (max-width: 800px) {
    .foot-link ul {
        flex-wrap: wrap;
    }
    .foot-nav-links
    {
        padding: 0.7rem;
    }
    .content-linkedin
    {
        column-gap: 5rem;
        justify-content: flex-start;
        align-items: flex-start;
    }
}
@media screen and (max-width: 700px) {
    .foot-nav-links
    {
        font-size: 13px;
    }
   .box p {
    font-size: 13px;
   }
   .copyright p
   {
    font-size: 13px;
   }
}
@media screen and (max-width: 680px) {
   .whole-container {
    align-items: center;
    column-gap: 5rem;
    padding: 1rem 1rem 1rem 1rem;
}
}
@media screen and (max-width: 600px) {
   .foot-logo
    {
        height: 6rem;
        width: 6rem;
    }
}
@media screen and (max-width: 550px) {
   .content-linkedin
   {
    column-gap: 1rem;
   }
}
@media screen and (max-width: 450px) {
   .content-linkedin
   {
    flex-direction: column;
    justify-content: center;
    align-items: center;
   }
   .foot-nav-links
    {
        padding: 0.3rem;
    }
    .linkedin-link
    {
        height: 2rem;
        width: 2rem;
    }
}

@media screen and (max-width: 450px) {
.foot-logo {
    flex-wrap: wrap;
    row-gap: 2rem;
}
.foot-content {
    display: flex;
    justify-content:center;
    column-gap: 2rem;
    align-items: center;
}
}
@media screen and (max-width: 450px) {
    .foot-logo{
        height: 5rem;
        width: 5rem;
    }
 .foot-nav-links
    {
        font-size: 12px;
    }
   .box p {
    font-size: 12px;
   }
   .copyright p
   {
    font-size: 12px;
   }
}
