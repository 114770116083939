
/* :root {
  --secondaryBackgroundClr: #f1f0f0;
  --primaryBackgroundClr:#e7e6e6;
  --primaryText:#000;
  --secondaryText:#182268;
  --white:#fff;
  --button:#c1272d;
} */
.navbar {
 background-color: rgba(255, 255, 255, 0.7);
  /* backdrop-filter: blur(10px); */
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2000;
  transition: transform 0.5s;
  transform: translateY(-100%);
}

.atTop {
   transform: translateY(0%);
   /* background-color: rgba(255, 255, 255, 0.5); */
   /* background: linear-gradient(180deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.89) 50%, rgba(255, 255, 255, 0.00) 100%); */
   position: absolute;
   background: transparent;
   border-bottom: 2px solid  rgba(255, 255, 255, 0.5);
  transition: transform 0.5s;
  
}
.atTop::before
{
  content: '';
  position: absolute;
  left: 0;
  top: 0px; 
  width: 100%;
  height: 23px;
  background-color: #182268;
  z-index: 1;
   opacity: 0;
  transition: opacity 0.5s ease;
}
.atTop:hover::before {
   opacity: 1;
}

.navbar:hover {
  background-color: #fff;
  transition: 0.5s ease-in-out;
}

/* .atTop:hover {
  background-color: transparent;
} */

.active-scroll {
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); 
  color: #182268;
  margin-top: 0rem;  
   transform: translateY(0%);
   position: fixed;
  backdrop-filter: blur(10px);
}


/* .hide {
  transform: translateY(-100%);
} */


.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height: 80px; */
  max-width: 1500px;
  
}


.nav-logo {
  align-items: center;
  margin-left: 1rem;
  margin-top: 0rem;
  /* flex-grow: 1; */
  /* margin-bottom: 2rem; */
  /* object-fit: cover; */ 
}



.atTop .nav-logo
{
  margin-top: 1.8rem;
  margin-left: 1rem;
  /* margin-bottom: 0.7rem; */
}
.nav-logo img {
   height: 5rem;
   /* width: 100%;
   height: 100%; */
    cursor: pointer;
    object-fit: cover;
    margin-top: 0.3rem;
    transition: opacity 0.3s ease-in-out; 
}

.atTop .nav-logo img
{
  margin-top: 0;
}

/* Additional style for hover effect */
.atTop .nav-logo img:first-child {
  position: absolute;
  /* top: 0;
  left: 0; */
  z-index: 2000;
}

.atTop .nav-logo img:last-child {
  position: relative;
  z-index: 2000;
}

.nav-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  /* text-align: center; */
  margin-right: 1rem;
  
}
.atTop .nav-menu
{
  margin-top: 3rem;
}
.nav-links {
  /* color: #182268; */
  /* color: #fff; */
  font-size: 18px;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
   text-align: center;
   font-style: normal;
font-weight: 540;
letter-spacing: 1px;
line-height: normal;
}

/* .atTop .nav-links:hover {
  color: #c12;
} */

.navbar .nav-links {
  color: #182268;
}
.atTop .nav-links {
  color: #fff;
}
.atTop .nav-links:hover {
  color: #c12;
}
.navbar .contact {
  color: #c12;
}
.atTop .contact {
  color: #fff;
}

.atTop:hover .nav-links
{
  color: #182268;
}
.nav-links:hover {
  color: #c12 !important;
}
.atTop:hover .contact
{
  color: #c12;
}
.fa-code {
  margin-left: 0.5rem;
}

/* .nav-item {
  margin-right: 1rem;
  font-size: 18px;
} */

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  /* background: #000; */
}

.nav-item .active {
  /* color: #000;
  border: 1px solid #000; */
  color: #c12 !important;
}

.nav-icon {
  display: none;
}

/* .contact {
  color: #c12;
} */

/**************** DROPDOWN ********************/
.about-cont {
  position: relative;
  transition: transform 0.5s;
}
.dropdown-content{
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 3px;
  transition: 0.5s ease-in-out;
}

.dropdown-menu {
  border-radius: 8px;
  color: #000;
  background-color: #d2d1d1;
  padding: 3px 10px 3px 10px;
   font-size: 15px;
   transition: 0.5s ease-in-out;
}


@media screen and (max-width: 1170px) {
  .nav-logo img
  {
    height: 4rem;
  }
  .nav-links
  {
    font-size: 16px;
  }
}
@media screen and (max-width: 1027px) {
  .nav-logo img
  {
    height: 3.5rem;
  }
  .nav-links
  {
    font-size: 15px;
  }
}


@media screen and (max-width: 950px) {
  .navbar {
    /* height: 80px; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* position: relative; */
    /* padding: 0; */
}
  .nav-menu {
    display: none;
    width: 30%;
    border-top: 1px solid #fff;
    position: absolute;
    top: 67px;
    right: -110%;
    opacity: 1;
    transition: all 0.5s ease;
    margin-right: 0;
  }
  .nav-logo{
    margin-left: 0;
  }
  .nav-logo img {
   height: 5rem;
  }
  .nav-menu.active {
    display: flex;
    flex-direction: column;
   background-color: rgba(255, 255, 255, 1);
    right: 0px;
    opacity: 1;
    /* transition: all 0.5s ease; */
    transition: 0.5s ease-in-out;
    z-index: 1;
  }
  .nav-item .active {
    color: #e7e6e6;
    border: none;
  }
  .nav-links {
    padding: 1rem;
    width: 100%;
    display: table;
    font-size: 18px;
    text-align: start;
  }
  .atTop .nav-links {
    color: #182268;
  }
  .nav-menu.active:hover {
    background-color: rgba(255, 255, 255, 1);
    transition: 0.5s ease-in;
  }
  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 2rem;
    cursor: pointer;
    color: var(--secondaryText);
    margin-right: 2rem;
    margin-top: 0rem;
  }
  .atTopHam
  {
     margin-top: 1.5rem;
  }
  .icon
  {
    height: 2rem !important;
    width: 2rem !important;
  }
}

@media screen and (max-width: 800px) {
  .nav-menu {
    align-items: flex-start;
  }
}
@media screen and (max-width: 600px) {
.nav-logo img {
   height: 3.5rem;
  }
   .atTopHam
  {
     margin-top: 0.8rem;
  }
   .icon
  {
    height: 1.5rem !important;
    width: 1.5rem !important;
  }
  .atTop .nav-menu {
  margin-top: 1rem;
}
.nav-menu {
  width: 50%;
  margin-top: -0.1rem;
}
.nav-links {
  padding: 0.5rem 0.3rem 0.3rem 1rem;
}
}


@media screen and (max-width: 400px) {
.nav-logo img {
   height: 3rem;
  }
  .nav-icon
  {
    margin-top: -1rem;
  }
   .atTopHam
  {
     margin-top: 0.2rem;
  }
   .icon
  {
    height: 1.3rem !important;
    width: 1.3rem !important;
  }
  .nav-menu {
  width: 60%;
  margin-top: -0.6rem;
} 
  /* .nav-icon {
    font-size: rem;
  } */

}