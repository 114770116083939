.why-sec
{
    /* padding: 2rem 0rem 2rem 0rem; */
    margin: 2rem 0rem 2rem 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    row-gap: 2rem;
}
.why-intro-whole
{
    display: flex;
    /* position: absolute; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1rem;
    width: 80%;
}
.why-intro-whole h1
{
    color: var(--secondaryText);
}
.why-intro-whole p
{
    font-size: 24px;
    /* color: var(--secondaryText); */
    color:#000;
    font-style: italic;
    font-weight: 500;
    line-height: 38px;
    text-align: center;
}

.why-grid-whole
{
    /* display: grid;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1rem;
    width: 80%; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    row-gap: 2rem;
    column-gap: 2rem;
    width: 80%;
    padding: 2rem 0 2rem 0;
}
.why-card
{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 2rem 2rem 2rem;
    
    /* box-shadow: 0px 0px 5px #F1F0F0 ; */
     box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.15);
     column-gap: 2rem;
}
.card-left
{
    border-radius: 50px 0 50px 0;
    flex-direction: row;
    background:linear-gradient(90deg, #FFF 0%, #FDFDFD 55%, #F6F6F6 74%, #EAEAEA 88%, #D9D9D9 100%); 
}
.card-right
{
    border-radius: 0px 50px 0px 50px;
    flex-direction: row-reverse;
    background:linear-gradient(90deg,#D9D9D9 0%, #EAEAEA 15%,#F6F6F6 34%,#FDFDFD 68%, #FFF 100%);
     
}
.why-card-cont
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    row-gap: 1rem;
}
.content-card-right
{
    align-items: flex-start;
}
.why-card-cont h1
{
    color: var(--secondaryText);
    text-align: right;
    font-size: 22px;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif
}
.why-card-cont p
{
    text-align: right;
    font-size: 16px;
}
.content-card-right p
{
    text-align: left;
}
.why-card img
{
    height: 6rem;
}

@media screen and (max-width: 1000px)
{
    .why-grid-whole
    {
    row-gap: 1rem;
    column-gap: 2rem;
    width: 90%;
    padding: 2rem 0 2rem 0;
    }
}
@media screen and (max-width: 950px){
    .why-intro-whole h1
{
    text-align: center;
    font-size: 32px;
}
.why-intro-whole p
{
    font-size: 22px;
}
    .why-card-cont h1{
        font-size: 20px;
    }
    .why-card-cont p
{
    font-size: 15px;
}
}
@media screen and (max-width: 850px){
    .why-card{
        padding: 1rem;
    }
   .why-card img
   {
    height: 5rem;
   }
   .why-card-cont h1{
        font-size: 18px;
    }
    .why-card-cont p
{
    font-size: 14px;
}
}
@media screen and (max-width: 700px){
    .why-intro-whole
    {
        row-gap: 0.5rem;
        width: 95%;
    }
    .why-intro-whole h1
{
    text-align: center;
    font-size: 30px;
}
.why-intro-whole p
{
    font-size: 20px;
    line-height:normal;
}
    .why-card{
        padding: 0.8rem;
        column-gap: 1rem;
    }
   .why-card img
   {
    height: 4rem;
   }
   .why-card-cont h1{
        font-size: 16px;
    }
    .why-card-cont p
{
    font-size: 13px;
}
}
@media screen and (max-width: 600px){
    .why-card{
        flex-direction: column-reverse;
        row-gap: 1rem;
    }
    .why-card-cont{
        justify-content: center;
        align-items: center;
    }
    .why-card-cont h1
    {
        text-align: center;
    }
    .why-card-cont p
    {
        text-align: center;
    }
   
}
@media screen and (max-width: 500px){
       .why-card img
   {
    height: 3rem;
   }
   .why-card-cont h1{
        font-size: 14px;
    }
    .why-card-cont p
{
    font-size: 12px;
}
    .why-intro-whole h1
{
    text-align: center;
    font-size: 28px;
}
.why-intro-whole p
{
    font-size: 18px;
    line-height:normal;
}
}
@media screen and (max-width: 400px){
    .why-grid-whole{
         grid-template-columns: repeat(1, 1fr);
    }
    
}