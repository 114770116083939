.carousel-container {
  max-width: 90%;
  /* margin-bottom: 5rem; */
  position: relative;
}



.slide-container {
 
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* width: 85% !important; */
  /* padding: 20px 0rem 20px 0rem;  */
  /* background-color: #fff; */
  border-radius: 25px;
   margin-top: 2.6rem;
margin-bottom: 2.6rem;
}

.second-cont {
 /* border: 2px solid var(--secondaryText); */
 padding: 0px 2rem 0px 2rem;
 text-decoration: none;
}

.slide-img {
  height: 15rem;
  height: 13rem;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.15);
}
.slide-img::after
{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    /* background-color: rgba(234, 234, 234, 0.01); */
    z-index: 0;
    border-radius: 25px 25px 0px 0;
}
.slide-img img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  border-radius: 25px 25px 0px 0px;
  position: absolute;
}
.slide-img h2 {
  text-align: center;
  font-weight: 700;
 color: #fff;
 position: relative;
 font-size: 32px;
 padding: 10px;
 text-transform: uppercase;
 width: 60%;
 z-index: 1;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 17rem;
  row-gap: 1rem;
   flex: 1; 
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  padding: 2rem 2rem 2rem 2rem;
  text-align: start;
  background-color: #fff;
  border-radius: 0px 0px 25px 25px;
  box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.15);
}

.content p{
  /* font-size: 20px; */
  color: var(--primaryText);
 text-align: center;
}


.content a {
  background-color: var(--button);
  color: var(--white);
  font-size: 14px;
  padding: 10px;
  font-weight: 500;
}

.carousel-container .slick-dots li button:before {
  color: #000;
}

.carousel__btn {
  align-items: center;
  background: 0;
  border: 0;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);
}
.carousel__btn--prev {
  left: -4rem;
}
.carousel__btn--next {
  right: -4rem;
}

.carousel__btn-arrow {
  /* border: solid #f1f0f0; */

  /* border: solid #c1272d; */
  border: solid #182268;
  /* border: solid #f1f0f0; */
  border-width: 0 0.2rem 0.2rem 0;
  height: 0.8rem;
  padding: 3px;
  width: 0.8rem;
  z-index: 10;
}
.carousel__btn-arrow--left {
  transform: rotate(135deg);
  margin-left: 1rem;
}
.carousel__btn-arrow--right {
  transform: rotate(-45deg);
  margin-right: 1rem;
}



.slick-prev, .slick-next  {
    font-size: 50px;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 50px !important;
    height: 50px !important;
    padding: 5px !important;
    transform: translate(0, -50%);
    cursor: pointer;
    color: #000 !important;
    outline: none;
    border-radius: 50%;
    background: #000 !important;
}

.slick-next::before {
     width: 50px !important;
    height: 50px !important;
}

.slick-prev {
    left: -50px !important;
}

.slick-dots li
{
   height: 10px !important;
  width: 10px !important;
  border-radius: 25px;
  background-color: #fff;
  margin-right: 8.5px !important;
  margin-left: 7.5px !important;
  cursor: pointer;
  margin-bottom: 1.4rem !important;
}

.slick-dots li button:before
{
  font-size: 0px !important;
}
.slick-dots .slick-active
{
   background-color: #000;
}

@media screen and (max-width: 1300px) {
  .slide-img
  {
    height: 15rem;
  }
  .content
  {
    height: 19rem;
  }
}
@media screen and (max-width: 1230px) {
  .slide-img
  {
    height: 12rem;
  }
  .content
  {
    height: 16rem;
  }
}
@media screen and (max-width: 1100px) {
  .carousel__btn--prev {
  left: -4rem;
}
}
@media screen and (max-width: 950px) {
.carousel__btn-arrow {
  /* height: 1.2rem; */
  padding: 3px;
  /* width: 1.2rem; */
  z-index: 10;
}
.carousel__btn--prev {
  left: -3rem;
}
.carousel__btn--next {
  right: -3rem;
}
.section-head
{
  font-size: 34px;
}
.slide-img h2
{
  font-size: 30px;
}
.content p {
  font-size: 15px;
}
}

@media screen and (max-width: 850px)
{
  .section-head
{
  font-size: 32px;
}
  .slide-img h2
{
  font-size: 28px;
}
  .content p {
  font-size: 14px;
}
}


@media screen and (max-width: 750px) {
  .second-cont
  {
    padding: 0px 1rem 0px 1rem;
  }
  .carousel__wrap {
  left:47%;
  width: 70%;
}
.carousel__container {
 
  width: 100%;
}
 .slide-img
  {
    height: 13rem;
  }
  .content
  {
    height: 17rem;
    padding: 1rem 1rem 1rem 1rem;
  }

}
@media screen and (max-width: 700px) {
  /* .carousel-container {
  max-width: 90%;
} */
.second-cont
  {
    padding: 0px 1rem 0px 1rem;
  }
  .carousel__btn--prev {
  left: -3rem;
}
  .carousel__btn--next {
  right: -3rem;
}
  .section-head
{
  font-size: 30px;
}
.slide-img h2
{
  font-size: 26px;
}
 .content p {
  font-size: 13px;
}
}
@media screen and (max-width: 600px) {
 .slide-container{
  flex-direction: column;
 }
 .slide-img {
  width: 100%;
 }

 .content {
  row-gap: 1rem;
 }
}

@media screen and (max-width: 570px)  {
  .slide-container
  {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
   .second-cont {
  width: 90%;
  /* padding: 20px 2rem 20px 2rem;  */
}
 .slide-img
  {
    height: 12rem;
  }
  .content
  {
    height: 13rem;
    padding: 1rem 2rem 1rem 2rem;
  }
    .carousel__btn--prev {
  left: -2rem;
}
  .carousel__btn--next {
  right: -2rem;
}
}

@media screen and (max-width: 450px) {
  .carousel-container {
  margin-bottom: 2rem;
}
.carousel__btn-arrow {
  height: 0.4rem;
  padding: 3px;
  width: 0.4rem;
  z-index: 10;
}
.slick-dots
{
  bottom: -50px !important;
}

 /* .slide-img.full-width {
  width: 90% !important;
   height: 12rem;
} */
}
@media screen and (max-width: 400px) {
    .section-head
{
  font-size: 28px;
}
 .slide-img h2{
  font-size: 22px;
}
.content p{
  font-size: 12px;
}
.content a{
  font-size: 10px;
}
}
