form {
    display: flex;
    flex-direction: column;
    row-gap: 3rem;
    margin-bottom: 3rem;
}
.vendor_sec
{
    padding: 2rem 0rem 1rem 0rem;
    margin: 0rem 0rem 0rem 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.getintouch
{
    padding: 0rem 0rem 1rem 0rem;
}

.form-heading p b
{
    text-decoration: underline;
    cursor: pointer;
}

.getintouch_whole
{
    width: 75%;
    padding: 5rem 3rem 6rem 3rem;
    border: 1px solid #000;
    border-radius: 25px;
     display: flex;
    justify-content: center;
    align-items: flex-start;
    column-gap: 5rem;
}

.getintouch_heading h1 {
    color: #000;
    font-family: 'Montserrat', sans-serif;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    width: 100%;
}

.getintouch_content
{
    width: 50%;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}
.getintouch_content table
{
    font-size: 20px;
    width: 100%;
    text-align: left;
}
.getintouch_content table tr th{
    margin-left: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.getintouch_content table tr td p{
    margin-left: 20px;
    margin-bottom: 20px;
}
.getintouch_content table tr td a{
    margin-left: 0px;
    margin-bottom: 20px;
    text-decoration: none;
    color: #000;
    font-size: 20px;
}
.getintouch_content p {
    font-size: 20px;
    width: 100%;
    text-align: left;
}
.getintouch_content  {
    font-size: 20px;
    width: 100%;
    text-align: left;
    text-decoration: none;
}

.vendor-form{
    display: flex;
    justify-content: center;
    align-items: center;
}
.vendor-form a{
    text-decoration: none;
    color: #000;
    padding: 0.7rem 3rem 0.7rem 3rem;
     font-style: normal;
     background-color: #C1272D;
     border: none;
     color: #fff;
     font-size: 20px;
    border-radius: 25px;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    margin-top: 1rem;
    text-align: center;
    margin-top: 0
}
/******************* FORM ******************/
.form_whole
{
    border-radius: 25px;
    border: 1px solid #000;
     width: 75%;
    padding: 2rem 3rem 1rem 3rem;
    display: flex;
    flex-direction: column;
    row-gap: 3rem;
}
.form-heading {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    row-gap: 2rem;
   
}
.form-heading h2{
 font-weight: 400;
 font-size: 24px;
 margin: 0;
}

.form-heading .vendor-form-para
{
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.form-heading .vendor-form-para p{
 font-weight: 400;
 font-size: 20px;
 text-align: center;
}

.form-group {
   display: grid;
   grid-template-columns: repeat(1, 1fr);
grid-template-rows: repeat(2, auto);
row-gap: 2rem;
}
.form-field 
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 0.7rem;
}
.form-field input {
    width: 70%; 
    height: 1rem;
    padding: 1rem 0.5rem 1rem 0.5rem; 
    font-size: 18px; 
    resize: vertical;
    border: none;
    border-bottom: 1px solid #000;
}

.form-field textarea {
     border-radius: 15px;
    padding: 0.5rem; 
    width: 70%; 
    height: 150px;
    font-size: 18px; 
    resize: vertical;
    border: 1px solid #000;
    font-family: 'Montserrat', sans-serif;
}

.submit-cont {
    display: flex;
    justify-content: center;
    align-items: center;
   
}
.submit-cont button {
     padding: 0.7rem 3rem 0.7rem 3rem;
     font-style: normal;
     background-color: #C1272D;
     border: none;
     color: #fff;
     font-size: 24px;
    border-radius: 25px;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

@media screen and (max-width: 950px){
    .form_whole
    {
        width: 90%;
    }
    .form-heading h2
    {
        font-size: 23px;
    }
    .form-heading p
    {
        font-size: 19px;
        text-align: center;
    }
    .form-field input
    {
        font-size: 17px;
    }
    .submit-cont button
    {
        font-size: 23px;
    }
    .getintouch_whole
    {
        width: 90%;
        column-gap: 3rem;
    }
    .getintouch_heading h1
    {
        font-size: 42px;
    }
    .getintouch_content p
    {
        font-size: 19px;
    }
    .getintouch_content table tr td a
    {
        font-size: 19px;
    }
}
@media screen and (max-width: 850px){
    .form-heading h2
    {
        font-size: 22px;
    }
    .form-heading p
    {
        font-size: 18px;
    }
    .form-field input
    {
        font-size: 16px;
    }
    .submit-cont button
    {
        font-size: 22px;
    }
    .getintouch_whole
    {
        width: 90%;
        column-gap: 2rem;
    }
    .getintouch_heading h1
    {
        font-size: 40px;
    }
    .getintouch_content p
    {
        font-size: 18px;
    }
    .getintouch_content table tr td a
    {
        font-size: 18px;
    }
}
@media screen and (max-width: 700px){
    .form-heading h2
    {
        font-size: 20px;
    }
    .form-heading p
    {
        font-size: 17px;
    }
    .form-field input
    {
        font-size: 15px;
    }
    .submit-cont button
    {
        font-size: 20px;
    }
    .getintouch_whole
    {
        width: 90%;
        column-gap: 1rem;
        padding: 2rem 2rem 2rem 2rem;
    }
    .getintouch_heading h1
    {
        font-size: 38px;
    }
    .getintouch_content p
    {
        font-size: 16px;
    }
    .getintouch_content table tr td a
    {
        font-size: 16px;
    }
}
@media screen and (max-width: 550px)
{       
    .getintouch_whole
    {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 2rem;
    }
}
@media screen and (max-width: 450px)
{       
    .form-heading h2
    {
        font-size: 18px;
    }
    .form-heading p
    {
        font-size: 16px;
    }
    .form-field input
    {
        font-size: 15px;
    }
    .submit-cont button
    {
        font-size: 20px;
    }
    .getintouch_whole
    {
        padding: 1rem;
    }
    .getintouch_heading h1
    {
        font-size: 36px;
    }
    .getintouch_content p
    {
        font-size: 14px;
    }
    .getintouch_content table tr td p {
        margin-left: 0.7rem;
        margin-bottom: 0;
    }
    .getintouch_content table tr td a
    {
        font-size: 14px;
    }
}