.section-about {
    padding: 0rem 3rem 0rem 3rem;
    /* margin-top: 1rem; */
    margin-bottom: 1.5rem;
}
.section-top
{
    margin-bottom: 3rem;
}
.section-est {
  background-color: var(--secondaryBackgroundClr);
  padding: 4rem 6rem 4rem 6rem;
}
/************** INTRODUCTION ******************/
.intro {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 3rem;
    margin-top: 3rem;
}
.intro-est
{
  /* column-gap: 0rem; */
   margin-top: 0rem;
}
.intro-img {
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
    width: 45%;
    /* margin-top: 2rem; */
}

.img-est
{
    width: 50%;
}

.image-inside1{
    height: 23rem;
    object-fit: cover;
    border-radius: 25px 25px 25px 25px;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
}

.intro-content {
    align-self: flex-start;
    padding: 0rem 1rem 0rem 1rem;
    width: 40%;
    /* margin-top: -10px; */
}

.intro-content p {
    color: #000;
    /* font-family: Montserrat; */
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.intro-content h2 {
    font-size: 30px;
    font-weight: 600;
    line-height: 35px;
    /* line-height: 42px; */
    font-style: italic;
    margin-top: 0;
}
.f-intro-para {
    line-height: 25px !important;
}

/***************** TEAM ******************/
.team-sec {
    background-color:var(--secondaryBackgroundClr);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.team-sec h1 {
    font-size: 34px;
    color: #182268;
    font-weight: 700;
    margin: 1rem 1rem 1.5rem 1rem;
}
.team-sec p {
    font-size: 22px;
    color: #000;
    margin: 0rem 0 1.5rem 0;
    text-align: center;
}

.team-img {
    /* height: 100%;
    width: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
    column-gap: 1rem;
}

.team-img img {
    height: 100%;
    width: 34%;
    object-fit: cover;
}
.team-img img:hover {
    transform: scale(1.3);
     transition: 0.5s ease-in-out;
}

@media screen and (max-width: 1150px)
{
    .intro-content
    {
        width: 50%;
    }
}
@media screen and (max-width: 1000px)
{
    .section-about {
        padding: 0rem 1.5rem 0rem 1.5rem;
    }
    .intro-est
    {
       padding: 2rem 0rem 2rem 0rem !important;
    }
    .intro-img
    {
        width: 40%;
    }
    .intro-content
    {
        width: 60%;
    }
    .section-company {
    padding: 1rem 3rem 1rem 3rem !important;
}
}
@media screen and (max-width: 950px)
{
    .intro-content h2
    {
        font-size: 28px;
    }
    .intro-content p
    {
        font-size: 18px;
    }
    .team-sec h1 {
    font-size: 32px;
}
.team-sec p {
    font-size: 20px;
}
}
@media screen and (max-width: 850px)
{
    .intro-content h2
    {
        font-size: 26px;
        line-height: 25px;
    }
    .intro-content p
    {
        font-size: 16px;
    }
    .f-intro-para {
        line-height: 25px !important;
    }
    .team-sec h1 {
    font-size: 30px;
}
.team-sec p {
    font-size: 18px;
}
}
@media screen and (max-width: 740px)
{
    .intro-content h2
    {
        font-size: 24px;
        line-height: normal;
    }
    .intro-content p
    {
        font-size: 14px;
    }
    .f-intro-para {
        line-height: normal !important;
    }
    .intro
    {
        column-gap: 1rem;
    }
    .intro-img
    {
        width: 50%;
    }
    .intro-content
    {
        width: 60%;
    }
     .team-sec h1 {
    font-size: 28px;
}
.team-sec p {
    font-size: 16px;
}
}
@media screen and (max-width: 600px)
{
    .intro
    {
        flex-direction: column;
        row-gap: 2rem;
        justify-content: center;
        align-items: center;
    }
    .intro-est
    {
        flex-direction: column-reverse !important;
    }
    .intro-img {
    width: 80%;
}
    .image-inside1{
    height: 18rem;
}
     .intro-content
    {
        width: 90%;
    }
    .section-company {
    padding: 1rem 2rem 1rem 2rem !important;
}
.team-sec h1
{
    margin:0.5rem 0rem 0.5rem 0rem
}
}
@media screen and (max-width: 400px)
{
    .intro
    {
        flex-direction: column;
        row-gap: 2rem;
        justify-content: center;
        align-items: center;
    }
    .intro-img {
    width: 100%;
}
    .image-inside1{
    height: 16rem;
}
    .intro-content h2
    {
        font-size: 24px;
        line-height: 25px;
    }
    .intro-content p
    {
        font-size: 14px;
        text-align: left;
    }
    .team-sec h1 {
    font-size: 28px;
    }
    .team-sec p {
    font-size: 15px;
    }
}