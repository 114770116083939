.carousel {
  height: 100%;
}

.carousel .card-container {
  height: 300px;
  position: relative;
  overflow: hidden;
}

.carousel .card {
  height: 100%;
  width: 33.3%;
  transition: all 1s;
  position: absolute;
  opacity: 0.8;
  transform: scale(0.8);
  z-index: 998;
  cursor: pointer;
  user-select: none;
}

.carousel .card img {
  height: 100%;
  width: 100%;
  border-radius: 25px;
  border: 5px solid #FFF;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  object-fit: cover;
}

.carousel .rects {
  /* height: 20px; */
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel .rects .rect {
  height: 10px;
  width: 10px;
  border-radius: 25px;
  margin-right: 8.5px;
  margin-left: 7.5px ;
  background-color: #fff;
  cursor: pointer;
}

.carousel .rects .active {
  background-color: #000;
}

@media screen and (max-width: 1000px)
{
    .carousel .card-container {
  height: 250px;
}
}
@media screen and (max-width: 780px)
{
    .carousel .card-container {
  height: 200px;
}
}
@media screen and (max-width: 600px)
{
    .carousel .card-container {
  height: 150px;
}
/* .carousel .card {
  width: 40%;
} */
}
@media screen and (max-width: 450px)
{    
  .carousel
  {
    width: 120%;
  }
  .carousel .card-container {
  height: 150px;
}

}
@media screen and (max-width: 400px)
{    
  .carousel
  {
    width: 120%;
  }
  /* .carousel .card-container {
  height: 150px;
} */

}