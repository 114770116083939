.slide-container-2 {
  display: flex !important;
  margin-top: 2.6rem;
margin-bottom: 2.6rem;
  align-items: center;
  /* padding: 20px 0 20px 0; */
}

.slide-img-whole{
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  flex-direction: column;
  row-gap: 1.5rem;
  /* padding: 0rem 2rem 0rem 2rem; */
}
.reverse-col
{
  flex-direction: column-reverse;
}

.slide-img-2 {
 /* width: 15rem; */
 /* height: 14rem; */
  /* position: relative; */
  display: flex;
  flex-direction: column;
  padding: 0rem 1rem 0rem 1rem;
  justify-content: center;
  align-items: center;
}
.both-in {
  border-radius: 25px;
box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.15);
 height: 12rem;
 /* width: 13rem; */
 /* width: 70%; */
  /* position: relative; */
  display: flex;
  flex-direction: column;
  padding: 1rem 3rem 1rem 3rem;
  justify-content: center;
  align-items: center;
}
.first-in {
  background: rgba(255, 255, 255, 0.90);
}
.second-in {
  background: #EAEAEA;
}

.slide-image {
  height: 100px;
}

.slide-image img {
  height: 100%;
  display: block;
  object-fit: contain;
}
.slide-img-2 h2 {
  text-align: center;
  font-weight: 700;
  color: #000;
}

@media screen and (max-width: 1200px) {
 .both-in
 {
    width: 70%;
 }
}
@media screen and (max-width: 1000px) {
 .both-in
 {
  height: 10rem;
    width: 60%;
 }
 .slide-image
 {
  height: 80px;
 }
 .slide-img-2 h2 
 {
  font-size: 18px;
 }
}
@media screen and (max-width: 1000px) {
  .slide-img-2
  {
     width: 100%;
  }
}


@media screen and (max-width: 650px) {
 .both-in
 {
  height: 8rem;
    width: 50%;
 }
 .slide-image
 {
  height: 60px;
 }
 .slide-img-2 h2 
 {
  font-size: 15px;
 }
}
@media screen and (max-width: 400px) {
 .content-2 h1{
  font-size: 18px;
}
.content-2 p{
  font-size: 10px;
}
}
