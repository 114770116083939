/* :root {
  --secondaryBackgroundClr: #f1f0f0;
  --primaryBackgroundClr:#e7e6e6;
  --primaryText:#000;
  --secondaryText:#182268;
  --white:#fff;
  --button:#c1272d;
} */
hr
{
    width: 100%;
    color: #182268;
    height: 0.5px;
    background-color: #000;
}
.section-company {
    margin-top: 1.5rem;
    padding: 1rem 8rem 1rem 8rem;
}

/***************** MISSION VISION *****************/
.mission-vision{
    background-color: var(--secondaryBackgroundClr);
     display: flex;
     flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 2rem;
    margin-bottom: 1.5rem;
     padding: 1rem 8rem 1rem 8rem;
}

.mv-whole{
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 2rem;
    width: 100%;
}

.mv-card
{
    width: 50%;
}

.mv-heading {
     
     background-size: 100%;
     background-position: center;
     background-size: cover;
    height: 10rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px 25px 0px 0;
}
.mission-img
{
    background-image: url(../../assets/images/company/mission.png);
    position: relative;
}
.vision-img
{
    background-image: url(../../assets/images/company/vision.png);
     position: relative;
}
.values-img
{
    background-image: url(../../assets/images/company/value.png);
    position: relative;
}
.black-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1); /* Background color with 50% opacity */
  z-index: 0; /* Places the background behind other elements */
  border-radius: 25px;
}
.mv-heading h1{
    color: #fff;
    font-size: 34px;
    font-family: 'Montserrat', sans-serif;
}

.mv-card h1 {
   color: #fff;
   font-weight: 700;
   font-size: 34px;
   font-family: 'Montserrat', sans-serif;
   z-index: 1;
}

.mv-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1rem;
    border-radius: 25px;
    background-color: #CFCFCF;
    padding: 0 0rem 1.2rem 0rem;
}
.mv-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 0.7rem;
    padding: 0 1rem 0rem 1rem;
    width: 100%;
}

.mv-content .para {
    font-weight: 700;
}
.mv-content p {
text-align: center;
font-style: normal;
font-size: 18px;
line-height: normal;
/* text-transform: uppercase; */
}
.mv-content h2 {
    color: var(--secondaryText);
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    font-style: normal;
    line-height: normal;
    margin: 0;
}
.priority {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 2rem;
    width: 100%;
    flex-wrap: wrap;
    row-gap: 1rem;
}
.priority p {
    background: linear-gradient(180deg, #A02739 0%, #182268 100%);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 4rem 1rem 4rem;
    width: 2rem;
    font-size: 16px;
    font-weight: 600;
    border-radius: 10px;
}


.values {
    /* background-color: #CFCFCF; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1rem;
    padding: 0rem 0rem 2rem 0rem !important;
    border-radius: 25px;
}
.values-content-whole
{
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    column-gap: 2.5rem;
    row-gap: 1rem;
    justify-content: center;
    align-items: center;
}
.values-card{
  width: 20rem;
}
.values-card img{
    width: 100%;
}

.value-after
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 0.5rem;
    margin: 0rem 0 0rem 0;
}
.value-after h1{
    color: #182268;
    font-size: 26px;
    font-family: 'Montserrat', sans-serif;
    margin-top: 1rem;
    text-align: center;
}
.value-after p
{
    text-align: center;
    font-size: 22px;
    margin: 0rem 0 1rem 0;
   font-family: 'Montserrat', sans-serif;
}

@media screen and (max-width: 1150px)
{
    .mv-content
    {
        width: 90%;
    }
    .priority
    {
        column-gap: 1rem;
    }
    .priority p
    {
        width: 1rem;
    }
}
@media screen and (max-width: 950px)
{
    .mv-card h1
    {
        font-size: 32px;
    }
   .mv-content p
   {
    font-size: 17px;
   }
    .priority p
    {
        font-size: 15px;
    }
    .mv-heading h1
    {
        font-size: 32px;
    }
    .value-after h1
    {
        font-size: 24px;
    }
    .value-after p
    {
    font-size: 20px;
    }
    .values-content-whole
    {
        padding: 0;
    }
}
@media screen and (max-width: 850px)
{
    .mv-card h1
    {
        font-size: 30px;
    }
   .mv-content p
   {
    font-size: 16px;
   }
    .priority p
    {
        font-size: 14px;
    }
    .priority p
    {
        width: 0rem;
    }
    .mv-heading h1
    {
        font-size: 30px;
    }
     .value-after h1
    {
        font-size: 22px;
    }
    .value-after p
    {
    font-size: 18px;
    }
    .values-card
    {
        width: 15rem;
    }
}
@media screen and (max-width: 800px)
{
    .priority p
    {
        padding: 1rem 5rem 1rem 5rem;
    }
}
@media screen and (max-width: 700px)
{
    .mv-card h1
    {
        font-size: 28px;
    }
   .mv-content p
   {
    font-size: 15px;
   }
   .priority
   {
        row-gap: 0.5rem;
   }
    .priority p
    {
        font-size: 13px;
    }
    .mv-heading h1
    {
        font-size: 28px;
    }
     .value-after h1
    {
        font-size: 20px;
    }
    .value-after p
    {
    font-size: 16px;
    }
}
@media screen and (max-width: 660px)
{
    .values-card
    {
        width: 12rem;
    }
}
@media screen and (max-width: 600px)
{
    .mv-heading
    {
        height: 8rem;
    }
    .mv-content
    {
        row-gap: 0.4rem;
    }
    .mv-whole{
    row-gap: 2rem;
    flex-direction: column;
    margin-top: 1rem;
}
.mv-card
{
    width: 100%;
}
.values
{
    width: 100%;
    padding: 0 !important;
}
.values-content-whole
{
    width: 100%;
    column-gap: 1rem;
}
}
@media screen and (max-width: 500px)
{
    .values-card
    {
        width: 10rem;
    }
}
@media screen and (max-width: 450px)
{
    .values-card
    {
        width: 9rem;
    }
}
@media screen and (max-width: 400px)
{
    .mv-card h1
    {
        font-size: 26px;
    }
   .mv-content p
   {
    font-size: 14px;
   }
    .priority p
    {
        font-size: 12px;
    }
    .mv-heading h1
    {
        font-size: 26px;
    }
     .values-card
    {
        width: 8rem;
    }
}
@media screen and (max-width: 380px)
{
    .values-content-whole
{
    width: 100%;
    column-gap: 0.5rem;
}
  .values-card
    {
        width: 7rem;
    }
}