.contact_sec
{
    padding: 2rem 0rem 0rem 0rem;
    margin: 1rem 0rem 1rem 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contact-whole
{
    width: 85%;
    padding: 0rem 3rem 0rem 3rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    column-gap: 3rem;
}
.talk-whole
{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    row-gap: 2rem;
    border: 1px solid #000;
    border-radius: 25px;
    padding: 2rem 2rem 2rem 2rem;
    width: 35%;
    height: 15rem;
}
.lets-talk
{
    width: 100%;
}
.lets-talk h1
{
    font-family: 'Montserrat', sans-serif;
    text-align: left;
    width: 100%;
    text-align: center;
}

.talk-whole form
{
    width: 100%;
}

.c-form-group {
   display: grid;
   grid-template-columns: repeat(1, 1fr);
grid-template-rows: repeat(2, auto);
row-gap: 1.5rem;
}
.c-form-field
{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    row-gap: 0.2rem;
}
.c-form-field input {
    width: 100%; 
    height: 1rem;
    padding: 0.5rem; 
    font-size: 15px; 
    resize: vertical;
    border: none;
    border-bottom: 1px solid #000;
}

.c-form-field textarea {
     border-radius: 15px;
    padding: 0.5rem; 
    width: 100%; 
    height: 150px;
    font-size: 18px; 
    resize: vertical;
    border: 1px solid #000;
    font-family: 'Montserrat', sans-serif;
}

.c-submit-cont {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
   
}
.c-submit-cont button {
     padding: 0.7rem 3rem 0.7rem 3rem;
     font-style: normal;
     background-color: #C1272D;
     border: none;
     color: #fff;
     font-size: 24px;
    border-radius: 25px;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.contact-touch-whole
{
    width: 50%;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    height: 15rem;
    /* height: 100%; */
}
.contact-touch
{
    border: 1px solid #000;
    border-radius: 25px;
    padding: 2rem 2rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    /* height: 35%; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.contact-touch h1
{
    font-family: 'Montserrat', sans-serif;
}
.contact-touch-content table
{
    font-size: 20px;
    width: 100%;
    text-align: left;
}
.contact-touch-content table tr th{
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contact-touch-content table tr td p{
    margin-left: 20px;
    margin-bottom: 20px;
}
.contact-touch-content p {
    font-size: 20px;
    width: 100%;
    text-align: left;
}
.contact-touch-content table tr td a{
    /* margin-left: 20px; */
    margin-bottom: 20px;
    text-decoration: none;
    color: #000;
    font-size: 20px;
}
.contact-touch-content p
{
    font-size: 20px;
}
.contact-touch-whole img
{
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 25px 25px 25px 25px;
}
.contact-touch-img
{
    height: 40%;
    border-radius: 25px 25px 25px 25px;
}

/************** WORK WITH US *******************/
.work-whole
{
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1.5rem;
}
.work-whole h2{
    margin: 0;
    color: var(--secondaryText);
    font-size: 27px; 
}

.work-whole p
{
    font-size: 27px;
    text-align: center;
}
span
{
   padding: 0.7rem 3rem 0.7rem 3rem;
     font-style: normal;
     background-color: #C1272D;
     border: none;
     color: #fff;
     font-size: 20px;
    border-radius: 25px;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    margin-top: 1rem;
    text-align: center;
}

/****************** CAREERS *******************/
.careers-whole
{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    row-gap: 2rem;
    column-gap: 2rem;
    width: 85%;
}
.career-card-whole
{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    /* row-gap: 1rem; */
    border: 1px solid #000;
    border-radius: 25px;
}
.image-heading {
  height: 15rem;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-heading img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  border-radius: 25px 25px 0px 0px;
  position: absolute;
}
.image-heading h2 {
  text-align: center;
  font-weight: 700;
 color: #fff;
 position: relative;
 font-size: 32px;
 padding: 10px;
 text-transform: uppercase;
}

.career-card-content
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1.5rem;
    padding: 2rem 2rem 2rem 2rem;
}

.career-card-content p
{
    font-size: 16px;
}

.join{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 0.6rem;
}
.why-choose
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1rem;
}
.why-content
{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    row-gap: 1rem;
    column-gap: 2rem;
    width: 100%;
}
.why-content p {
    background: linear-gradient(180deg, #A02739 0%, #182268 100%);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem 1rem 1rem 1rem;
    font-size: 14px;
    font-weight: 600;
    border-radius: 10px;
}
.journey
{
    font-weight: 300;
    text-align: center;
}

/****************** APPLY NOW *********************/
.apply-now
{
    position: relative;
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.apply-img::after
{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 0;
    border-radius: 25px;
}
.apply-img img
{
    position: relative;
    width: 100%;
}

/* apply-now-link{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
} */

.apply-now a
{
    position: absolute;
    font-size: 32px;
    color: #fff;
    text-decoration: none;
    font-weight: 600;
}
.apply-now p{
    position: absolute;
    color: #fff;
    font-size: 22px;
    margin-top: 5rem;
}


/******************* OPPORTUNITIES ******************/
.explore-opp
{
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 2rem;
}
.opp
{
    display: flex;
    flex-direction: column;
}
.explore-opp h2
{
    font-size: 24px;
    font-weight: 500;
    margin: 0;
}
.explore-opp p
{
    font-size: 20px;
    text-align: center;
}
.opp a{
    padding: 0.7rem 3rem 0.7rem 3rem;
     font-style: normal;
     background-color: #C1272D;
     border: none;
     color: #fff;
     font-size: 20px;
    border-radius: 25px;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    margin-top: 1rem;
    text-align: center;
    text-decoration: none;
}

@media screen and (max-width: 1100px) {
    .contact-whole {
        width: 95%;
        padding: 0rem 2rem 0rem 2rem;
    }
}

@media screen and (max-width: 950px) {
    .lets-talk h1 {
        font-size: 30px;
    }
    .contact-touch h1 {
        font-size: 30px;
    }
    .contact-touch-content p {
        font-size: 18px;
    }
    .contact-touch-content table tr td a
    {
        font-size: 18px;
    }
    .c-submit-cont button {
       font-size: 20px;
    }
    .work-whole h2 {
        font-size: 24px;
    }
    .work-whole p{
        font-size: 24px;
    }
    .work-whole {
        row-gap: 1rem;
    }
    span {
        font-size: 18px;
    }
    .apply-now h2 {
        font-size: 28px;
    }
    .explore-opp h2 {
        font-size: 20px;
    }
    .confused p {
        font-size: 20px;
    }
    .explore-opp {
        row-gap: 1rem;
    }
    span  {
        margin-top: 0.5rem;
    }
    .c-form-field textarea {
        font-size: 15px;
    }
}

@media screen and (max-width: 900px) {
    .contact-touch{
        padding: 1rem;
    }
    form{
        margin-bottom: 1rem;
        row-gap: 1rem;
    }
    .talk-whole{
        padding: 1rem 1rem 1rem 1rem;
    }
    .c-form-group {
        row-gap: 1rem;
    }
    .c-form-field input{
        width: 80%;
    }
    .c-form-field textarea{
        width: 80%;
    }
    .c-submit-cont button {
       padding: 0.7rem 1.5rem 0.7rem 1.5rem;
    }
    .careers-whole {
        width: 95%;
    }
    .image-heading h2 {
        font-size: 28px;
    }
    .career-card-content p {
        font-size: 14px;
    }
    .why-content {
        column-gap: 1rem;
    }
}
@media screen and (max-width: 850px) {
    .contact_sec {
        padding: 0;
    }
     .lets-talk h1 {
        font-size: 28px;
    }
    .contact-touch h1 {
        font-size: 28px;
    }
    .contact-touch-content p {
        font-size: 16px;
    }
    .contact-touch-content table tr td a
    {
        font-size: 16px;
    }
    .c-submit-cont button {
       font-size: 18px;
    }
    .work-whole h2 {
        font-size: 22px;
    }
    .work-whole p{
        font-size: 22px;
    }
    .work-whole {
        row-gap: 1rem;
    }
    span{
        font-size: 16px;
        padding: 0.5rem 1rem 0.5rem 1rem;
        margin-top: 0;
    }
    .talk-whole{
        height: 13.5rem;
    }
    .vendor-form a{
    font-size: 16px;
        padding: 0.5rem 1rem 0.5rem 1rem;
}
     .apply-now h2 {
        font-size: 24px;
    }
    .explore-opp h2 {
        font-size: 18px;
    }
    .confused p {
        font-size: 18px;
    }

}

@media screen and (max-width: 750px) {
    .contact-whole {
        width: 100%;
        column-gap: 1rem;
        padding: 0rem 0rem 0rem 0rem;
    }
    .image-heading {
        height: 10rem;
    }
    .why-content p {
        padding: 8px;
    }
    .career-card-content {
        padding: 1.5rem;
        row-gap: 1rem;
    }
    .careers-whole {
        row-gap: 1rem;
        column-gap: 1rem;
    }
}

@media screen and (max-width: 700px) {
     .lets-talk h1 {
        font-size: 26px;
    }
    .contact-touch h1 {
        font-size: 26px;
    }
    .contact-touch-content p {
        font-size: 14px;
    }
    .contact-touch-content table tr td a
    {
        font-size: 14px;
    }
    .c-submit-cont button {
       font-size: 16px;
    }
    .contact-touch-content table tr td p {
        margin-left: 10px;
        margin-bottom: 8px;
    }
    .contact-touch-content table tr th{
        margin-bottom: 8px;
    }
    .work-whole h2 {
        font-size: 18px;
    }
    .work-whole p{
        font-size: 18px;
    }
    span{
        font-size: 14px;
    }
}

@media screen and (max-width: 650px) {
    .careers-whole {
        grid-template-columns:repeat(1, 1fr);
    }
    .contact_sec{
        padding: 0;
    }
}
@media screen and (max-width: 600px) {
    .c-form-field textarea{
        height: 50px;
    }
    .contact-whole{
        flex-direction: column;
        row-gap: 2rem;
    }
    .contact-touch-whole {
        width: 90%;
        height: 12rem;
        /* flex-direction: row;
        justify-content: center;
        align-items: center;
        column-gap: 1rem; */
    }
    .talk-whole {
        width: 90%;
        height: 10rem;
        row-gap: 3rem;

    }
    .contact-touch {
        width: 100%;
    }
    .contact-touch-img {
        width: 100%;
        height: 12rem;
    }

}

@media screen and (max-width: 400px) {
    .contact-whole{
        row-gap: 1rem;
    }
    .contact-touch-whole {
        row-gap: 1rem;
    }
    .image-heading h2 {
        font-size: 24px;
    }
    .career-card-content p {
        font-size: 12px;
    }
    .image-heading {
        height: 8rem;
    }
    .work-whole h2 {
        font-size: 14px;
    }
    .work-whole p{
        font-size: 14px;
    }
    span{
        font-size: 12px;
    }
     .apply-now h2 {
        font-size: 20px;
    }
    .explore-opp h2 {
        font-size: 16px;
    }
    .confused p {
        font-size: 16px;
    }
}