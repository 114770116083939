/* .content {
    opacity: 1;
    position: absolute;
    bottom: 40px;
    left: 45%;
    margin-left: -40%;
    width: 50%;
    border-radius: 10px;
    color: #fff;
    padding: 10px;
    font-size: 40px;
    text-align: center;
    transition: opacity .35s ease-in-out;
} */
.slideshow-cont {
    border-radius: 10px;
    bottom: 80px;
    color: var(--secondaryText);
    left: 45%;
    margin-left: -35%;
    /* padding: -15px; */
    padding: 10px;
    position: absolute;
    text-align: left;
    transition: opacity .35s ease-in-out;
    width: 45%; 
    font-size: 50px;
    /* font-family: Montserrat; */
    font-style: normal;
    /* font-weight: 600; */
    line-height: normal;
}
.slideshow-cont b {
    font-weight: 600;
}
.carousel .thumbs-wrapper {
    display: none !important;
}
.carousel .slide img {
    height: 100% !important;
    object-fit: cover !important;
}

@media screen and (min-width: 950px)
{
    .slide-cont {
    height: 70vh;
    width: 100%;  
    }
    .slide-card {
    height: 70vh;
    width: 100%; 
    }    
}

@media screen and (max-width: 1300px)
{
    .slide-cont {
    height: 60vh;
    width: 100%;  
    }
    .slide-card {
    height: 60vh;
    width: 100%; 
    }    
}

@media screen and (max-width: 750px)
{
    .slide-cont {
    height: 50vh;
    width: 100%;  
    }
    .slide-card {
    height: 50vh;
    width: 100%; 
    }    
}

 @media screen and (max-width: 780px) {
     .slide-cont {
    height: 60vh;
    width: 100%;    
    }
    .slide-card {
    height: 60vh;
    width: 100%;
    }
} 
 @media screen and (max-width: 620px) {
     .slide-cont {
    height: 45vh;
    width: 100%;    
    }
    .slide-card {
    height: 45vh;
    width: 100%;
    }
} 
 @media screen and (max-width: 540px) {
     .slide-cont {
    height: 40vh;
    width: 100%;    
    }
    .slide-card {
    height: 40vh;
    width: 100%;
    }
} 
 @media screen and (max-width: 460px) {
     .slide-cont {
    height: 35vh;
    width: 100%;    
    }
    .slide-card {
    height: 35vh;
    width: 100%;
    }
} 
 @media screen and (max-width: 400px) {
     .slide-cont {
    height: 30vh;
    width: 100%;    
    }
    .slide-card {
    height: 30vh;
    width: 100%;
    }
} 