/* :root {
  --secondaryBackgroundClr: #f1f0f0;
  --primaryBackgroundClr:#e7e6e6;
  --primaryText:#000;
  --secondaryText:#182268;
  --white:#fff;
} */

.trusted-slide-img {
  height: 12rem !important;
  object-fit: cover;
}
.trusted-slide-img img {
  width: 90% !important;
  /* height:10rem !important; */
  object-fit: cover !important;
  box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.15);
 margin-top: 2rem;
margin-bottom: 2rem;
}

.trusted-slide-img::after
{
   box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.15);
}

/* .trusted-arrow
{
   border: solid #f1f0f0;
} */

@media screen and (max-width: 1260px) {
  .trusted-slide-img {
    height: 10rem !important;
  }
}
@media screen and (max-width: 1200px) {
  .trusted-slide-img {
    height: 12rem !important;
  }
}
@media screen and (max-width: 980px) {
  .trusted-slide-img {
    height: 10rem !important;
  }
}
@media screen and (max-width: 450px) {
  .trusted-slide-img {
    height: 8rem !important;
  }
}
@media screen and (max-width: 450px) {
  .trusted-slide-img {
    height: 7rem !important;
  }
}
/* @media screen and (max-width: 480px) {
  .trusted-slide-img img {
    width: 100% !important;
  }
} */