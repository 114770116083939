
.section-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* row-gap: 1rem; */
    /* margin-top: 2rem; */
    padding: 2.6rem 0rem 1.8rem 0rem;
    /* width: 80%; */
  background-color: var(--secondaryBackgroundClr);
   margin-top: 1.5rem;
   overflow: hidden;
}
.product-sec {
  margin-top: 1rem;
}
.tagline-section {
   background-color: transparent ;
   margin-top: 0rem;
   padding: 1rem 0rem 1rem 0rem;
}
.trusted-sec {
  margin-bottom: 1.5rem ;
}
.section-head {
  /* background-color:var(--secondaryBackgroundClr); */
  color: var(--secondaryText);
  font-weight: 700;
  /* padding: 0.8rem; */
  border-radius: 20px;
  margin-top: 0rem;
  text-align: center;
  /* font-size: 35px; */
}

/* .product-sec {
  border: 2px solid #000;
  box-shadow: 0px 4px 0px rgba(0, 0, 0,0.3); 
} */
.value {
  padding: 2.1rem 5rem 2.1rem 5rem;
}

/************* TAGLINE *****************************/
.tagline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 0rem;
  width: 90%;
  text-align: center;
}

.tagline h1{
  color: var(--secondaryText);
  /* font-size: 30px; */
  font-weight: 700;
  font-size: 32px;
  line-height: normal;
  /* line-height: 4px; */
}
.tagline h2{
  color: var(--button);
  font-size: 28px;
  font-style: italic;
  font-weight: 600;
  line-height: 4px;
}

.tagline p{
  color: var(--secondaryText);
  /* font-size: 28px; */
  font-size: 24px;
  /* line-height: 38px; */
  font-weight: 400;
line-height: 32px;
  text-align: center;
  /* letter-spacing: 0.5px; */
  width: 85%;
}

@media screen and (max-width: 950px) {

.tagline h1{
  /* font-size: 21px; */
  font-size:29px;
}
.tagline h2{
  /* font-size: 21px; */
  font-size:25px;
}
.tagline p {
  /* font-size: 14px; */
  font-size:  21px;
}
}

@media screen and (max-width: 1050px) {
  .value {
  padding: 2.1rem 3rem 2.1rem 3rem;
}
}
@media screen and (max-width: 850px) {
  .tagline {
    width: 100%;
  }
  .tagline h1{
  /* font-size: 21px; */
  font-size:27px;
}
.tagline h2{
  /* font-size: 21px; */
  font-size:23px;
}
.tagline p {
  /* font-size: 14px; */
  font-size:  19px;
}
}
@media screen and (max-width: 570px) {

    .tagline h1{
  /* font-size: 21px; */
  font-size:25px;
}
.tagline h2{
  /* font-size: 21px; */
  font-size:21px;
}
.tagline p {
  /* font-size: 14px; */
  font-size:  17px;
  line-height: 20px;
}
.section-cont{
  padding: 1rem 0rem 0.8rem 0rem;
}
  .section-head {
  padding: 0.6rem;
   border-radius: 15px;
  font-size: 25px;
}
}
@media screen and (max-width: 400px) {
   .tagline h1{
  /* font-size: 21px; */
  font-size:23px;
}
.tagline h2{
  /* font-size: 21px; */
  font-size:19px;
}
.tagline p {
  /* font-size: 14px; */
  font-size:  15px;
  line-height: 20px;
}
}
@media screen and (max-width: 350px) {
   .tagline h1{
  /* font-size: 21px; */
  font-size:20px;
}
.tagline h2{
  /* font-size: 21px; */
  font-size:16px;
}
.tagline p {
  /* font-size: 14px; */
  font-size:  12px;
  line-height: 20px;
}
}
