:root {
  --secondaryBackgroundClr: #f1f0f0;
  --primaryBackgroundClr:#e7e6e6;
  --primaryText:#000;
  --secondaryText:#182268;
  --white:#fff;
  --button:#c1272d;
}
body {
   padding: 15px;
   /* margin: 0; */
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

h1 {
  /* font-size: 30px; */
  font-family: 'Times New Roman', serif;
  /* 36px */
  font-size: 2.25rem;
  margin: 0;
}
h2{
  /* font-size: 21px; */
  font-size:1.3125rem;
  /* font-family: 'Montserrat', sans-serif; */
  font-family: 'Montserrat', sans-serif;
}
p {
  /* font-size: 16px; */
  font-size:  1rem;
  margin:0;
  font-family: 'Montserrat', sans-serif;
}
a{
  /* font-size: 14px; */
  font-size: 0.875rem;
  font-family: 'Montserrat', sans-serif;
}

@media screen and (max-width: 950px) {
  body {
    padding: 12px;
    margin: 0;
  }
  /* h1 {
  font-size: 33px;
}
h2{
  font-size:18px;
}
p {
  font-size:  12px;
}
a{
  font-size: 12px;
} */
}
@media screen and (max-width: 450px) {
  body {
    padding: 9px;
    margin: 0;
  }
  h1 {
  font-size: 30px;
}
h2{
  /* font-size: 21px; */
  font-size:15px;
}
p {
  /* font-size: 14px; */
  font-size:  10px;
}
a{
  /* font-size: 14px; */
  font-size: 10px;
}
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
