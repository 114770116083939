.sust-sec
{
   padding: 2rem 0rem 2rem 0rem;
    margin: 1rem 0rem 1rem 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ethical-whole
{
     width: 80%;
    /* padding: 0rem 3rem 0rem 3rem; */
    display: flex;
    justify-content: center;
    align-items: flex-start;
    column-gap: 3rem;
}
.ethical-whole img
{
   width: 37.5%;
   object-fit: cover;
}
.ethical-whole .ethical-content
{
    width: 62.5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    row-gap: 2rem;
}
.hr-sust hr
{
    width: 80%;
    height: 0.2px !important;
    background-color: #f1f0f0;
}
.ethical-content h1
{
    color: var(--secondaryText);
    font-family: 'Montserrat', sans-serif;
    font-size: 45px;
    width: 100%;
    font-style: normal;
    text-transform: uppercase;
    text-align: left;
}
.ethical-content p{
    font-size: 28px;
   font-style: normal;
font-weight: 300;
line-height: 100.9%;
text-align: left;
}
.ethical-content img
{
    height: 8rem;
    width: 10rem;
    object-fit: contain;
}
.cert-whole
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1rem;
    width: 87%;
}
.cert-whole h1
{
    color: var(--secondaryText);
}

.sec-back
{
    background-color: #F1F0F0;
}
.offer-line
{
    width: 80%;
    font-size: 30px;
    text-align: center;
    font-style: italic;
font-weight: 500;
line-height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/************** SUSTAINABILITY LIST *****************/
.sust-list-whole
{
    /* height: 100vh; */
    width: 100%;
    /* padding: 0rem 3rem 0rem 3rem; */
    display: flex;
    justify-content: center;
    align-items: flex-start;
    column-gap: 3rem;
}
.sust-list-whole ul
{
    width: 40%;
    list-style-type:disc;
    /* padding: 2rem 0rem 2rem 0rem; */
}
.sust-list-whole ul li
{
    font-size: 24px;
    font-style: normal;
font-weight: 400;
line-height: 45px;
}
.sust-list-whole .sust-list-images
{
    width: 40%;
}
.sust-list-images img
{
    width: 100%;
     object-fit: cover;
}
.list-img-div
{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.list-img-div img
{
    width: 50%;
    object-fit: cover;
}

/*************** SUSTAINABILITY SAPPHIRE *******************/
.sust-sapphire-whole
{
     display: flex;
    width: 80%;
    column-gap: 4rem;
}
.sust-reverse
{
    flex-direction: row-reverse;
}
.sust-sapphire-whole img
{
     width: 50%;
   object-fit: cover;
}
.sust-sapphire-content
{
     width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 3rem;
    padding: 2rem 0rem 2rem 0rem;
}
.sust-sapphire-content h1
{
    color: var(--secondaryText);
}
.sust-sapphire-content p
{
    font-size: 24px;
    font-weight: 400;
}
.content-para ul
{
    list-style-type:disc;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 2rem;
}
.content-para ul li
{
    font-size: 24px;
    font-weight: 400;
}
/* .content-reverse p
{
    text-align: right;
} */

@media screen and (max-width: 1100px)
{
    .ethical-whole
{
     width: 90%;
}
   .ethical-whole img
{
   width: 50%;
   object-fit: cover;
}
.ethical-whole .ethical-content
{
    width: 50%;
}

.sust-list-whole ul
{
    width: 30%;
    list-style-type:disc;
    /* padding: 2rem 0rem 2rem 0rem; */
}
.sust-list-whole .sust-list-images
{
    width: 50%;
}
}

@media screen and (max-width: 950px){
    .ethical-whole
    {
     width: 95%;
    }
    .ethical-whole .ethical-content
    {
    width: 100%;
    row-gap: 1rem;
    }
    .ethical-content h1 {
        font-size: 40px;
    }
    .ethical-content p{
    font-size: 24px;
}
.ethical-content img
{
    height: 4rem;
    width: 6rem;
    object-fit: contain;
}
.offer-line
{
    font-size: 26px;
    line-height: 35px;
}
.sust-list-whole ul li{
    font-size: 20px;
    line-height: 35px;
}
.sust-sapphire-content h1
{
    font-size: 32px;
}
.sust-sapphire-content p
{
    font-size: 20px;
}
.content-para ul li
{
    font-size: 20px;
}

}
@media screen and (max-width: 850px) {
    .sust-list-whole{
        column-gap: 1rem;
    }
.sust-list-whole ul li{
    font-size: 18px;
    line-height: 30px;
}
.sust-list-whole .sust-list-images
{
    width: 60%;
}
.sust-sapphire-content h1
{
    font-size: 30px;
}
.sust-sapphire-content p
{
    font-size: 18px;
}
.sust-sapphire-content
{
    row-gap: 1rem;
}
.content-para ul li
{
    font-size: 18px;
}
}
@media screen and (max-width: 750px) {
        .offer-line
{
    font-size: 22px;
    line-height: 30px;
}
    .ethical-content h1 {
        font-size: 35px;
    }
    .ethical-content p{
    font-size: 20px;
}
.ethical-content img
{
    height: 4rem;
    width: 6rem;
    object-fit: contain;
}
.sust-list-whole ul li{
    font-size: 16px;
    line-height: 25px;
}
.sust-sapphire-whole
{
    width: 90%;
    column-gap: 2rem;
}
}

@media screen and (max-width: 600px)
{
    .sust-list-whole
    {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
    .sust-list-whole ul
    {
    width: 80%;
    }
    .sust-list-whole .sust-list-images
{
    width: 80%;
}
.sust-sapphire-content h1
{
    font-size: 26px;
}
.sust-sapphire-content p
{
    font-size: 16px;
}
.content-para ul li
{
    font-size: 16px;
}
}
@media screen and (max-width: 560px)
{
    .sust-sec
    {
        padding: 1rem 0rem 1rem 0rem;
    }
    .ethical-whole
    {
     width: 90%;
    /* padding: 0rem 3rem 0rem 3rem; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 2rem;
    }
    .ethical-whole .ethical-content
    {
        align-items: center;
    }
    .ethical-content h1 {
        text-align: center;
    }
    .ethical-content p{
    text-align: center;
}
    .ethical-content img
{
   display: none;
}
}

@media screen and (max-width: 450px)
{
     .offer-line
{
    font-size: 20px;
    line-height: 25px;
}
.sust-sapphire-whole
{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1rem;
    width: 90%;
}
.sust-sapphire-whole img
{
     width: 100%;
   object-fit: cover;
}
.sust-sapphire-content
{
    width: 100%;
    padding: 0;
    justify-content: center;
    align-items: center;
}
.sust-sapphire-content h1
{
    text-align: center;
}
.sust-sapphire-content p
{
    text-align: center;
}
}
@media screen and (max-width: 400px)
{
    .ethical-content h1 {
        font-size: 28px;
    }
    .ethical-content p{
    font-size: 18px;
}
}